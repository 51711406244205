.layer-selector-item {
  margin: 0 8px;
}
.layer-selector--item {
  max-width: 100px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 90%;
  user-select: none;
}
.layer-selector-item-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  position: relative;
  display: block;
}
.layer-selector-item.radio,
.layer-selector-item.checkbox {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
}
.layer-selector-item.radio input[type="checkbox"],
.layer-selector-item.checkbox input[type="checkbox"],
.layer-selector-item.radio input[type="radio"],
.layer-selector-item.checkbox input[type="radio"] {
  position: absolute;
  margin-left: -20px;
}
.layer-selector-item.radio label,
.layer-selector-item.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}
.layer-selector {
  background: #fff;
  pointer-events: auto;
  max-width: 150px;
}
.layer-selector--width {
  width: 8em;
}
.layer-selector__toggle {
  width: 36px;
  height: 36px;
  padding: 5px;
  display: block;
}
.layer-selector--layers {
  min-height: 36px;
  min-width: 36px;
  padding: 10px 5px;
  white-space: nowrap;
}
.layer-selector--separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eee;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.layer-selector--top {
  top: 0;
}
.layer-selector--right {
  right: 0;
}
.layer-selector--bottom {
  bottom: 0;
}
.layer-selector--left {
  left: 0;
}
.layer-selector--hidden {
  display: none;
}
